$(document).ready(function() {
    $.urlParam = function(name){
        var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
        if (results == null){
            return null;
        } else {
            return decodeURI(results[1]) || 0;
        }
    }   

    var page = $.urlParam('page');

    if (($('.header-itf-content').length) || (page == 'edit_itf_contents') || (page == 'create_content_library') || (page == 'itf_contents_success')) {
        check_current_itf_cat();
        check_current_itf_type();
    }
    
    $("p[class^='expert-bio-']").on('click', function() {

        var className = $(this).attr('content-id');         
        var icon  = $('#expert-bio-'+className+'-font');        
        if (icon.attr('data-icon') == 'caret-down') 
        {
            icon.attr('data-icon', 'caret-up');
        } 
        else 
        {
            icon.attr('data-icon', 'caret-down');
        }
     
    })
     
     increase_height_itf_video();

    $("#category_level_1_id").on("change", function ()
    {
        var category_id = $(this).val();
        var content_item_id = $("input[name=id]").val();
        var token = $("input[name=token]").val();

        jQuery.ajax({
            type: "POST",
            url: "../content/ajax_itf_category.php",
            data: {
                id          : category_id,
                content_id  : content_item_id,
                ptToken     : token
            },
            success: function (data){
                $("#subcategory_level_1_id option").remove();
                $("#subcategory_level_1_id").append(data);
            }
        });
    });

    $("#category_level_2_id").on("change", function ()
    {
        var category_id = $(this).val();
        var content_item_id = $("input[name=id]").val();
        var token = $("input[name=token]").val();

        jQuery.ajax({
            type: "POST",
            url: "../content/ajax_itf_category.php",
            data: {
                id          : category_id,
                content_id  : content_item_id,
                ptToken     : token
            },
            success: function (data){
                $("#subcategory_level_2_id option").remove();
                $("#subcategory_level_2_id").append(data);
            }
        });
    });

    $("#category_level_3_id").on("change", function ()
    {
        var category_id = $(this).val();
        var content_item_id = $("input[name=id]").val();
        var token = $("input[name=token]").val();

        jQuery.ajax({
            type: "POST",
            url: "../content/ajax_itf_category.php",
            data: {
                id          : category_id,
                content_id  : content_item_id,
                ptToken     : token
            },
            success: function (data){
                $("#subcategory_level_3_id option").remove();
                $("#subcategory_level_3_id").append(data);
            }
        });
    });

    $("body").on("click", ".publish-btn", function (){
        var contentid = $(this).attr("data-content-id");
        var button = $(this);
        var title = $(this).attr("title");
        var token = $('input[name="token"]').val();
        title = title.trim();

        var action = "Offline";

        if (title === "Publish Content")
        {
            action = "Publish";
        }

        button.attr("class", "fa fa-spinner fa-fw fa-pulse spinner-btn");

        $.ajax({
            url: '../content/ajax_itf_publish_content.php',
            dataType: "json",
            data: {
                id: contentid,
                action: action,
                token: token
            },
            success: function(data)
            {
                try
                {
                    if (data.output != "")
                    {

                        setTimeout(function(){
                          var button = $('.spinner-btn');
                            if (action == "Publish")
                            {
                                button.attr("class", "fa fa-toggle-on publish-btn ");
                                button.attr("style", "");
                                button.attr("title", "Deactivate Content");
                            }
                            else
                            {
                                button.attr("class", "fa fa-toggle-off publish-btn");
                                button.attr("style", "");
                                button.attr("title", "Publish Content");
                            }
                        },500);

                    }
                    else if(data.errormessage !== "")
                    {
                        alert(data.errormessage);
                    }

                }
                catch (e)
                {
                    alert("Something went wrong.");
                }
            }
        });
    });


    $('#banner_type').on('change', function()
    {
        var selectedVal = $(this).val();
        var token = $('input[name="token"]').val();
        var optionsSelect =  $(".itf-banner-items").select2({ theme: 'bootstrap-5', placeholder: '-- Please Select --'});
        if (selectedVal !== '')
        {
            var action = "get_banner_info";
            $.ajax({
                url: '../content/ajax_itf_common.php',
                dataType: "json",
                data: {
                    value         : selectedVal,
                    token         : token,
                    action        : action
                },          
                success: function(data)
                {
                    try
                    {                    
                        
                         // create the option and append to Select2
                         for (var i = 0; i < data.output.length; i++)
                         {
                            var option = new Option(data.output[i].val, data.output[i].id, true, true);
                            optionsSelect.append(option).trigger('change');

                            // manually trigger the `select2:select` event
                            optionsSelect.trigger({
                                type: 'select2:select',
                                params: {
                                    data: data
                                }
                            });

                         }
                           
                     
                    
                    }
                    catch (e)
                    {
                        alert("Something went wrong.");
                    }
                }

             }); 
        }

    })

    $('#itf_type').on('change', function()
    {
        console.log("change");
        var content_type = $(this).val();       
        if ((content_type == 'kaltura_video') || (content_type == 'vcasmo_video') || (content_type == "sequence_photos") || (content_type == "youtube_video") || (content_type == "soundcloud") || (content_type == "vimeo_video"))
        {
            $('#itf_video_size').hide();
            if (content_type == 'kaltura_video')
            {
                $('#itf_video_size').show();
            }
            
            $(".field-wrapper").each(function(){
                var id = $(this).attr('id');
                if (id && id.indexOf('itf_video_embed') >= 0)
                {
                    $(this).show();
                }
            });
            
            $(".field-wrapper").each(function(){
                var id = $(this).attr('id');
                if (id && id.indexOf('itf_audio') >= 0)
                {
                    $(this).hide();
                }
            });

            $(".field-wrapper").each(function(){
                var id = $(this).attr('id');
                if (id && id.indexOf('itf_document') >= 0)
                {
                    $(this).hide();
                }
            });

            $(".field-wrapper").each(function(){
                var id = $(this).attr('id');
                if (id && id.indexOf('itf_image') >= 0)
                {
                    $(this).hide();
                }
            });

            $(".field-wrapper").each(function(){
                var id = $(this).attr('id');
                if (id && id.indexOf('itf_html_embed') >= 0)
                {
                    $(this).hide();
                }
            });

            $(".field-wrapper").each(function(){
                var id = $(this).attr('id');
                if (id && id.indexOf('itf_uploaded_video_url_') >= 0)
                {
                    $(this).hide();
                }
            });
        }
        else if (content_type == 'uploaded_video')
        {
            $('#itf_video_size').hide();
            if (content_type == 'kaltura_video')
            {
                $('#itf_video_size').hide();
            }
            
            $(".field-wrapper").each(function(){
                var id = $(this).attr('id');
                if (id && id.indexOf('itf_video_embed') >= 0)
                {
                    $(this).hide();
                }
            });
            
            $(".field-wrapper").each(function(){
                var id = $(this).attr('id');
                if (id && id.indexOf('itf_audio') >= 0)
                {
                    $(this).hide();
                }
            });

            $(".field-wrapper").each(function(){
                var id = $(this).attr('id');
                if (id && id.indexOf('itf_document') >= 0)
                {
                    $(this).hide();
                }
            });

            $(".field-wrapper").each(function(){
                var id = $(this).attr('id');
                if (id && id.indexOf('itf_image') >= 0)
                {
                    $(this).hide();
                }
            });

            $(".field-wrapper").each(function(){
                var id = $(this).attr('id');
                if (id && id.indexOf('itf_html_embed') >= 0)
                {
                    $(this).hide();
                }
            });

            $(".field-wrapper").each(function(){
                var id = $(this).attr('id');
                if (id && id.indexOf('itf_uploaded_video_url_') >= 0)
                {
                    $(this).show();
                }
            });
        }
        else if (content_type == 'audio')
        {
            $(".field-wrapper").each(function(){
                var id = $(this).attr('id');
                if (id && id.indexOf('itf_audio') >= 0)
                {
                    $(this).show();
                }
            });

            $(".field-wrapper").each(function(){
                var id = $(this).attr('id');
                if (id && id.indexOf('itf_video_embed') >= 0)
                {
                    $(this).hide();
                }
            });

            $('#itf_video_size').hide();

            $(".field-wrapper").each(function(){
                var id = $(this).attr('id');
                if (id && id.indexOf('itf_document') >= 0)
                {
                    $(this).hide();
                }
            });

            $(".field-wrapper").each(function(){
                var id = $(this).attr('id');
                if (id && id.indexOf('itf_image') >= 0)
                {
                    $(this).hide();
                }
            });

            $(".field-wrapper").each(function(){
                var id = $(this).attr('id');
                if (id && id.indexOf('itf_html_embed') >= 0)
                {
                    $(this).hide();
                }
            });

            $(".field-wrapper").each(function(){
                var id = $(this).attr('id');
                if (id && id.indexOf('itf_uploaded_video_url_') >= 0)
                {
                    $(this).hide();
                }
            });
        }
        else if (content_type == 'document')
        {
            $(".field-wrapper").each(function(){
                var id = $(this).attr('id');
                if (id && id.indexOf('itf_audio') >= 0)
                {
                    $(this).hide();
                }
            });

            $(".field-wrapper").each(function(){
                var id = $(this).attr('id');
                if (id && id.indexOf('itf_video_embed') >= 0)
                {
                    $(this).hide();
                }
            });

            $('#itf_video_size').hide();

            $(".field-wrapper").each(function(){
                var id = $(this).attr('id');
                if (id && id.indexOf('itf_document') >= 0)
                {
                    $(this).show();
                }
            });

            $(".field-wrapper").each(function(){
                var id = $(this).attr('id');
                if (id && id.indexOf('itf_image') >= 0)
                {
                    $(this).hide();
                }
            });

            $(".field-wrapper").each(function(){
                var id = $(this).attr('id');
                if (id && id.indexOf('itf_html_embed') >= 0)
                {
                    $(this).hide();
                }
            });

            $(".field-wrapper").each(function(){
                var id = $(this).attr('id');
                if (id && id.indexOf('itf_uploaded_video_url_') >= 0)
                {
                    $(this).hide();
                }
            });
        }
        else if (content_type == 'image')
        {
            $(".field-wrapper").each(function(){
                var id = $(this).attr('id');
                if (id && id.indexOf('itf_audio') >= 0)
                {
                    $(this).hide();
                }
            });

            $(".field-wrapper").each(function(){
                var id = $(this).attr('id');
                if (id && id.indexOf('itf_video_embed') >= 0)
                {
                    $(this).hide();
                }
            });

            $('#itf_video_size').hide();

            $(".field-wrapper").each(function(){
                var id = $(this).attr('id');
                if (id && id.indexOf('itf_document') >= 0)
                {
                    $(this).hide();
                }
            });

            $(".field-wrapper").each(function(){
                var id = $(this).attr('id');
                if (id && id.indexOf('itf_image') >= 0)
                {
                    $(this).show();
                }
            });

            $(".field-wrapper").each(function(){
                var id = $(this).attr('id');
                if (id && id.indexOf('itf_html_embed') >= 0)
                {
                    $(this).hide();
                }
            });

            $(".field-wrapper").each(function(){
                var id = $(this).attr('id');
                if (id && id.indexOf('itf_uploaded_video_url_') >= 0)
                {
                    $(this).hide();
                }
            });
        }
        else if (content_type == 'html')
        {
           $(".field-wrapper").each(function(){
                var id = $(this).attr('id');
                if (id && id.indexOf('itf_audio') >= 0)
                {
                    $(this).hide();
                }
            });

            $(".field-wrapper").each(function(){
                var id = $(this).attr('id');
                if (id && id.indexOf('itf_video_embed') >= 0)
                {
                    $(this).hide();
                }
            });

            $('#itf_video_size').hide();

            $(".field-wrapper").each(function(){
                var id = $(this).attr('id');
                if (id && id.indexOf('itf_document') >= 0)
                {
                    $(this).hide();
                }
            });

            $(".field-wrapper").each(function(){
                var id = $(this).attr('id');
                if (id && id.indexOf('itf_image') >= 0)
                {
                    $(this).hide();
                }
            });

            $(".field-wrapper").each(function(){
                var id = $(this).attr('id');
                if (id && id.indexOf('itf_html_embed') >= 0)
                {
                    $(this).show();
                }
            });

            $(".field-wrapper").each(function(){
                var id = $(this).attr('id');
                if (id && id.indexOf('itf_uploaded_video_url_') >= 0)
                {
                    $(this).hide();
                }
            });
        }
        else
        {
            $(".field-wrapper").each(function(){
                var id = $(this).attr('id');
                if (id && id.indexOf('itf_audio') >= 0)
                {
                    $(this).hide();
                }
            });

            $(".field-wrapper").each(function(){
                var id = $(this).attr('id');
                if (id && id.indexOf('itf_video_embed') >= 0)
                {
                    $(this).hide();
                }
            });

            $('#itf_video_size').hide();

            $(".field-wrapper").each(function(){
                var id = $(this).attr('id');
                if (id && id.indexOf('itf_document') >= 0)
                {
                    $(this).hide();
                }
            });

            $(".field-wrapper").each(function(){
                var id = $(this).attr('id');
                if (id && id.indexOf('itf_image') >= 0)
                {
                    $(this).hide();
                }
            });

            $(".field-wrapper").each(function(){
                var id = $(this).attr('id');
                if (id && id.indexOf('itf_html_embed') >= 0)
                {
                    $(this).hide();
                }
            });

            $(".field-wrapper").each(function(){
                var id = $(this).attr('id');
                if (id && id.indexOf('itf_uploaded_video_url_') >= 0)
                {
                    $(this).hide();
                }
            });
        }
    });

    function check_current_itf_type()
    {
        var content_type = $('#itf_type').val();
        
        $('#itf_video_size').hide();

        $(".field-wrapper").each(function(){
            var id = $(this).attr('id');
            if (id && id.indexOf('itf_video_embed') >= 0)
            {
                $(this).hide();
            }
        });

        $(".field-wrapper").each(function(){
            var id = $(this).attr('id');
            if (id && id.indexOf('itf_audio') >= 0)
            {
                $(this).hide();
            }
        });

        $(".field-wrapper").each(function(){
            var id = $(this).attr('id');
            if (id && id.indexOf('itf_document') >= 0)
            {
                $(this).hide();
            }
        });

        $(".field-wrapper").each(function(){
            var id = $(this).attr('id');
            if (id && id.indexOf('itf_image') >= 0)
            {
                $(this).hide();
            }
        });

        $(".field-wrapper").each(function(){
            var id = $(this).attr('id');
            if (id && id.indexOf('itf_html_embed') >= 0)
            {
                $(this).hide();
            }
        });

        $(".field-wrapper").each(function(){
            var id = $(this).attr('id');
            if (id && id.indexOf('itf_uploaded_video_url_') >= 0)
            {
                $(this).hide();
            }
        });

        if ((content_type == 'kaltura_video') || (content_type == 'vcasmo_video') || (content_type == "sequence_photos") || (content_type == "youtube_video") || (content_type == "soundcloud") || (content_type == "vimeo_video"))
        {            
            $(".field-wrapper").each(function(){
                var id = $(this).attr('id');
                if (id && id.indexOf('itf_video_embed') >= 0)
                {
                    $(this).show();
                }
            });
         
            $('#itf_video_size').hide();
            if (content_type == 'kaltura_video')
            {
                $('#itf_video_size').show();
            }
        }
        else if (content_type == 'uploaded_video')
        {
            $(".field-wrapper").each(function(){
                var id = $(this).attr('id');
                if (id && id.indexOf('itf_uploaded_video_url_') >= 0)
                {
                    $(this).show();
                }
            });
        }
        else if (content_type == 'audio')
        {
            $(".field-wrapper").each(function(){
                var id = $(this).attr('id');
                if (id && id.indexOf('itf_audio') >= 0)
                {
                    $(this).show();
                }
            });
        }
        else if (content_type == 'document')
        {
            $(".field-wrapper").each(function(){
                var id = $(this).attr('id');
                if (id && id.indexOf('itf_document') >= 0)
                {
                    $(this).show();
                }
            });
        }
        else if (content_type == 'image')
        {
            $(".field-wrapper").each(function(){
                var id = $(this).attr('id');
                if (id && id.indexOf('itf_image') >= 0)
                {
                    $(this).show();
                }
            });       
        }
        else if (content_type == 'html')
        {
           $(".field-wrapper").each(function(){
                var id = $(this).attr('id');
                if (id && id.indexOf('itf_html_embed') >= 0)
                {
                    $(this).show();
                }
            });
        }        
    }

    if ($('.related_contents_select').length) $(".related_contents_select").select2({ theme: 'bootstrap-5', placeholder: '-- Please Select --'});
    if ($('.related_contents_books').length) $(".related_contents_books").select2({ theme: 'bootstrap-5', placeholder: '-- Please Select --'});
    if ($('.related_contents_player_age').length) $(".related_contents_player_age").select2({ theme: 'bootstrap-5', placeholder: '-- Please Select --'});
    if ($('.related_contents_player_levels').length) $(".related_contents_player_levels").select2({ theme: 'bootstrap-5', placeholder: '-- Please Select --'});   
    if ($('.related_contents_player_game_types').length) $(".related_contents_player_game_types").select2({ theme: 'bootstrap-5', placeholder: '-- Please Select --'});
    if ($('.itf-banner-items').length) $(".itf-banner-items").select2({ theme: 'bootstrap-5', placeholder: '-- Please Select --'});
    if ($('#content-audience-types-select2-box').length) $(".related_contents_audience_types").select2({ theme: 'bootstrap-5', placeholder: '-- Please Select --'});
    if ($('#books-game-types-select2-box').length) $(".books_game_types").select2({ theme: 'bootstrap-5', placeholder: '-- Please Select --'});
    
    if ($('#itf-search-btn').length) {
         get_generic_text_select2(); 
    }

     if ($('#filter-course-search-btn').length) {
         get_generic_text_select2(); 
    }
   
    if ($(".tags_select").length) $(".tags_select").select2({ theme: 'bootstrap-5', placeholder: '-- Please Select --'});

    if ($("#content-items-tbl").length) {
        cmsLoadingStatus = true;
        loadOverlay();

        var content_items_tbl = $("#content-items-tbl").DataTable({
            "ajax": "../content/ajax_read_itf_content_items.php",
            "rowReorder": true,
            "pageLength": 10,
            "columnDefs": [  
                { className: "reorder", "targets": [ 0 ] },   
                { targets: 0, visible: false },
                { targets: 1, visible: false },      
                { targets: 7, visible: false },
                { targets: 9, visible: false },
                { "orderData": [ 7 ], "targets": 8 },
                { "orderData": [ 9 ], "targets": 10 },
                { 'targets': [2,3,11], 'orderable': false },
            ],
            order: [[0, 'asc']],
        });

        setTimeout(function(){
            cmsLoadingStatus = false;

            content_items_tbl.on('row-reorder', function ( e, diff, edit ) {

                cmsLoadingStatus = true;
                loadOverlay();

                var elementOrder = [];                
                for ( var i=0, ien=diff.length ; i<ien ; i++ ) 
                {
                    var newOrder = diff[i].newPosition + 1;
                    var rowData = content_items_tbl.row( diff[i].node ).data();
                    var element = {"key" : rowData[1], "order" : newOrder};
                    elementOrder.push(element);
                }

                console.log(elementOrder)

                var token = $('input[name="token"]').val();
                $.ajax({
                    url: '../content/ajax_itf_common.php',
                    type: "post",
                    dataType: "json",
                    async: false,
                    data: {
                        token    : $('input[name="token"]').val(),
                        action   : 'update_content_items_order',
                        academy  : $("input[name=academy]").val(),
                        elements : elementOrder
                    },
                    success: function(data)
                    {                
                        //reload_content_items_table();
                        cmsLoadingStatus = false;
                    }
                });
            });

        }, 500);
    }
    

    $("#federation-items-tbl").dataTable();
    $("#itf-sponsors-tbl").dataTable();
    $("#country_itf").dataTable();
    $("#lang_itf").dataTable();
    $("#services_itf").dataTable();
    $("#itf-books-tbl").dataTable();

    var tbl_banners = $("#itf-banners-tbl").DataTable({
        "pageLength": 10,
        "rowReorder": true,
        "columnDefs": [
            { className: "reorder", "targets": [ 0 ] },            
            { targets: 1, visible: false }
        ]        
    });

    tbl_banners.on( 'row-reorder', function ( e, diff, edit ) {
        var elementOrder = [];
        for ( var i=0, ien=diff.length ; i<ien ; i++ ) 
        {
            var newOrder = diff[i].newPosition + 1;
            var rowData = tbl_banners.row( diff[i].node ).data();
            var element = {"key" : rowData[1], "order" : newOrder};
            elementOrder.push(element);
        }

        var token = $('input[name="token"]').val();
        $.ajax({
            url: '../content/ajax_itf_common.php',
            type: "post",
            dataType: "json",
            async: false,
            data: {
                token    : $('input[name="token"]').val(),
                action   : 'update_banners_order',
                academy  : $("input[name=academy]").val(),
                elements : elementOrder
            },
            success: function(data)
            {                
                reload_banners_table();
            }
        });
    });

   // $('#reservationtime').daterangepicker({ timePicker: true, timePickerIncrement: 30, format: 'MM/DD/YYYY h:mm A' });

    if($('#visited-tbl').find('tr:first-child').find('td').length > 1) 
    {
      $("#visited-tbl").dataTable();
    }

    if($('#bookmarked-tbl').find('tr:first-child').find('td').length > 1) 
    {
      $("#bookmarked-tbl").dataTable();
    }


    $('body').on('change keyup','.select2-search__field', function(e)
    {
        var code = e.keyCode || e.which;        
        if (e.type == 'change' || (e.type == 'keyup' && code == 13))
        {
            //1. Have to get maximum id from the database for tags or books
            var added_val       =  $(this).val();
            var type_of_item    =  $(this).closest('div').attr('id');            
            if (type_of_item == 'tags-select2-box')
            {               
                var tags_div_name = 'tags_select';
                var option_exist   = check_if_option_exist(tags_div_name, added_val);
                if (!option_exist && added_val !== "" && added_val.length > 1)
                {
                    addItemsManuallySelect2(tags_div_name, added_val);
                }
                $(this).val('');
            }
            else if (type_of_item == 'books-select2-box')
            {
                var books_div_name = 'related_contents_books';
                var option_exist   = check_if_option_exist(books_div_name, added_val);
                if (!option_exist && added_val !== "" && added_val.length > 1)
                {
                    addItemsManuallySelect2(books_div_name, added_val);
                }
                $(this).val('');
            }
        }

    });

    //itf voucher code button
    $('#itf_voucher_btn').on('click', function() 
    {
        //First get the voucher
        var voucher = $('#itf_voucher_code_val').val();     
        if (voucher !== "")
        {
             //Then redeeemed this voucher store the user id
            var action  = "redeemedVoucher";
            var token = $('input[name="token"]').val();
            $.ajax({
                url: '../content/ajax_itf_common.php',
                dataType: "json",
                data: {
                    voucher         : voucher,
                    token           : token,
                    action          : action
                },          
                success: function(data)
                {
                    try
                    {

                        if (data.output !== undefined)
                        {
                            var success = "Voucher code : " +voucher + " Successfully redeeemed.  Please click on the button below to continue." ;

                            $(".itf_voucher_error").text(success);
                           $(".itf_voucher_error").css("color","#008f70");
                            $('#icoach-btn-back-pay').show();
                        }
                        if(data.error !== undefined)
                        {
                            
                           
                            //alert(error_entry);
                           $(".itf_voucher_error").text(data.error);
                           $(".itf_voucher_error").css("color","red");
                        }

                    }
                    catch (e)
                    {
                        alert("Something went wrong.");
                    }
                }

            });        
        }   
        else
        {
           $(".itf_voucher_error").text("Empty Vouchers are not allowed");
            $(".itf_voucher_error").css("color","red");
        }
       
    });

    //itf worldpay code button
    $('#itf_worldpay_btn').on('click', function() 
    {  
        var action  = "worldpay";
        var token = $('input[name="token"]').val();
        $('#itf_worldpay_btn').addClass('disabled');
        $('#loading-dialog').show();
        $.ajax({
            url: '../content/ajax_itf_common.php',
            dataType: "json",
            data: {             
                token           : token,
                action          : action
            },          
            success: function(data)
            {
                try
                {
                    if (data.output != "")
                    {
                        $('#itf_worldpay_btn').removeClass('disabled');
                        $('#loading-dialog').hide();
                        location.href = data.output;                        
                    }

                }
                catch (e)
                {
                    $('#loading-dialog').hide();
                    alert("Something went wrong.");
                }
            }

        });        
    });

    $('#itf-search-item').bind('enterKey', function(e){
        
        var search_text = $('#itf-search-item').val();
        var action  = "searchAll";
        var token = $('input[name="token"]').val();
        cmsLoadingStatus = true;
        loadOverlay();

        $.ajax({
            url: '../content/ajax_itf_common.php',
            dataType: "json",
            data: {
                search_text     : search_text,
                token           : token,
                action          : action
            },
            beforeSend: function(){
                //Show a itf loading icon on search
                $('#loading-dialog').show();
            },
            success: function(data)
            {
                cmsLoadingStatus = false; 

                try
                {
                    if (data.output != "")
                    {
                         setTimeout(function(){
                            if ($(".title-summary-icons-box").length)
                            {
                                $('#loading-dialog').hide();
                                $('.title-summary-icons-box').html(data.output).addClass('searchResultsWrap');
                                $('.title-summary-icons-box').removeClass('video-ind-page-wrap');
                            }
                            else
                            {
                                $('#loading-dialog').hide();
                                $('#itf-editors-pick-main').html(data.output).addClass('searchResultsWrap');
                                $('#latest_recommended_box').hide();
                            }

                        },500);

                    }
                    else if(data.errormessage != "")
                    {
                        alert(data.errormessage);
                    }

                }
                catch (e)
                {
                    alert("Something went wrong.");
                }
            }

        });

    });

    $('#itf-search-item').keyup(function(e)
    {
        if(e.keyCode == 13)
        {
            if ($(this).val() == '') return;

            $(this).trigger("enterKey");
        }
    });
    $('#itf-search-btn').on('click', function(e) 
    {
        $('#itf-search-item').trigger("enterKey");
    });

    $('#itf-pwd-change').on('click', function() {
        var action = "itfpwdchange";
        var token = $('input[name="token"]').val();
        $.ajax({
            url: '../content/ajax_itf_common.php',
            dataType: "json",
            data: {
                action          : action,
                token           : token,
            },
            success: function(data)
            {
                try
                {
                    if (data.output != "")
                    {
                        window.location.replace(data.output);
                    }
                }
                catch (e)
                {
                    alert("Something went wrong.");
                }
            }
        });
    });

    $("#itf-email-address").on("change", function()
    {
        var email_add_val = $(this).val();
        if (validate_itf_email(email_add_val))
        {
            $(".itf-register-btn").removeClass("disabled");
        }
        else
        {
            alert("Invalid Email Entered");
        }

    });

    $(".itf-register-btn").on('click', function()
    {
        var email_address = $("#itf-email-address").val();
        var token = $('input[name="token"]').val();
        var action  = 'itfRegister';
        $.ajax({
            url: '../content/ajax_itf_common.php',
            dataType: "json",
            data: {
                email           : email_address,
                action          : action,
                token           : token,
            },
            success: function(data)
            {
                try
                {
                    if (data.output != "")
                    {
                        window.location.replace(data.output);
                    }
                }
                catch (e)
                {
                    alert("Something went wrong.");
                }
            }
        });


    });

    $('.itf-watch-later').on('click', function() {
        var contentId = $(this).attr("content-id");
        var token = $('input[name="token"]').val();
        var action  = 'itfBookmark';
        $.ajax({
            url: '../content/ajax_itf_common.php',
            dataType: "json",
            data: {
                action          : action,
                id              : contentId,
                token           : token,
            },
            success: function(data)
            {
                try
                {
                    if (data.output == "exist")
                    {
                        alert("Item already bookmarked.");
                    }
                    else
                    {
                        alert("Item bookmarked successfully.");
                    }
                }
                catch (e)
                {
                    alert("Something went wrong.");
                }
            }
        });


     });

    $("#filter-search-btn").on('click', function(e) {

        var btnVal = $('#filter-search-btn').html();
        var search_text = $('#itf-search-item').val();
        var token = $('input[name="token"]').val();
        var action  = 'applyFilter';
        var filter_play_level   = get_select_multiple_data('player-level-filter');
        var media_type_filter   = get_select_multiple_data('media-type-filter');
        var expert_type_filter  = get_select_multiple_data('expert-filter');
        var gender_type_filter  = get_select_multiple_data('topic-filter');

        $.ajax({
            url: '../content/ajax_itf_common.php',
            dataType: "json",
            data: {
                search_text     : search_text,
                action          : action,
                filterLevel     : filter_play_level,
                filterMedia     : media_type_filter,
                expertFilter    : expert_type_filter,
                genderFilter    : gender_type_filter,
                token           : token
            },
            beforeSend: function(){
                //Show a itf loading icon on search
                $('#loading-dialog').show();
            },
            success: function(data)
            {
                try
                {
                    if (data.output != "")
                    {

                        setTimeout(function(){
                        if ($(".title-summary-icons-box").length)
                            {

                                $('#loading-dialog').hide();
                                $('.title-summary-icons-box').html(data.output).addClass('searchResultsWrap');
                                $('.title-summary-icons-box').removeClass('video-ind-page-wrap');
                            }
                            else
                            {

                                $('#loading-dialog').hide();
                                $('#itf-editors-pick-main').html(data.output).addClass('searchResultsWrap');
                                $('#latest_recommended_box').hide();
                            }

                        },500);
                    }
                }
                catch (e)
                {
                     alert("Something went wrong.");
                     setTimeout(function(){
                        $('#loading-dialog').hide();
                         },500);
                }
            }
        });


    });
   
    $( ".itf_remove_multi").on("click", function() {
        var docid = $(this).attr("docId");
        var docRowId = "#text_doc_" + docid;
        var token = $('input[name="token"]').val();
        var decision =  confirm('Are you sure you want to delete this?');
        if (decision)
        {
            //Sent an ajax to remove from table
            var action = "removeDoc";
            $.ajax({
                url: '../content/ajax_itf_common.php',
                dataType: "json",
                data: {
                    docId              : docid,
                    token              : token,                    
                    action             : action
                },                
                success: function(data)
                {
                    try
                    {
                        if (data.output != "")
                        {
                            $(docRowId).remove();
                        }
                    }
                    catch (e)
                    {
                        alert("Something went wrong.");
                    }
                }
            });
        }
    });

    $(".cat-dropdown").on('click', function(){
        var sub_cat_id = $(this).attr("data-id");
        var parent_id   = $(this).attr("data-parent");
        var token = $('input[name="token"]').val();
        var action  = 'getsubcategories';

        $.ajax({
            url: '../content/ajax_itf_common.php',
            dataType: "json",
            data: {
                subId              : sub_cat_id,
                token              : token,
                parentId           : parent_id,
                action             : action
            },
            beforeSend: function(){
                 $('#loading-dialog').show();
            },
            success: function(data)
            {
                try
                {
                    if (data.output != "")
                    {
                     setTimeout(function(){
                            $('#loading-dialog').hide();
                            $('#contentSubcategory').html(data.output);
                        },500);


                    }
                }
                catch (e)
                {
                    alert("Something went wrong.");
                }
            }
        });
    });

    $('#itf-registration-btn').on('click', function() {
            var title                   = $('#salutation').val();
            var first_name              = $('#given-name').val();
            var last_name               = $('#family-name').val();
            var pass_given_name         = $('#pass-given-name').val();
            var pass_fam_name           = $('#pass-family-name').val();
            var country                 = $('#itf-nationality').val();
            var dob                     = $('#itfbirthdate').val(); console.log(dob);
            var gender                  = $('#itfgender').val();
            var email                   =  $('input[name="email"]').val();
            var lang                    = $('#itflang').val();
            var service                 = "7CA527AA-7F9C-4495-A628-105529CC05AB";//This is the service guiid for WAOS Portal
            var serviceline             = $('#itfserviceline').val();
            var token                   = $('input[name="token"]').val();
            var action                  = "itfRegister";
            var guid                    = $('input[name="guid"]').val();
            var startdateandtime        = $('#reservationtime').val();
            var redirect                = $('input[name="redirect"]').val();
            var parentFirstName         = $('#pFname').val();
            var parentLastName          = $('#pLname').val();
            var parentEmail            = $('#pEmail').val();
            if (serviceline == null)
            {
                startdateandtime = "";
            }

            //TO:DO Validations of all the inputs
            //console.log(redirect);
            validate = validate_user_form_inputs(title, first_name, last_name, country, dob, gender, lang);

            if (validate)
            {
                $.ajax({
                    url: '../content/ajax_itf_common.php',
                    dataType: "json",
                    data: {
                        title            : title,
                        firstname        : first_name,
                        lastname         : last_name,
                        passgivenname    : pass_given_name,
                        passfamname      : pass_fam_name,
                        country          : country,
                        dob              : dob,
                        gender           : gender,
                        email            : email,
                        lang             : lang,
                        service          : service,
                        serviceline      : serviceline,
                        token            : token,
                        action           : action,
                        guid             : guid,
                        startdateandtime : startdateandtime,
                        parent_first_name: parentFirstName,
                        parent_last_name : parentLastName,
                        parent_email     : parentEmail,

                    },
                    beforeSend: function(){
                         $('#loading-dialog').show();
                    },
                    success: function(data)
                    {
                        try
                        {
                             if (data.output == "Success")
                             {
                                setTimeout(function(){
                                    $('#loading-dialog').hide();
                                    location.href = redirect;
                                },500);
                             }
                        }
                        catch (e)
                        {
                            alert("Something went wrong.");
                        }
                    }
                });

            }
            else
            {
               alert("Please fill in all the mandatory fields.");
            }

        });

    $('body').on('click', '#edit-profile-btn',function(){

        cmsLoadingStatus = true;
        loadOverlay();

        var title            = $('input[name="title"]').val();
        var firstname        = $('input[name="firstname"]').val();
        var lastname         = $('input[name="lastname"]').val();
        var token            = $('input[name="token"]').val();

        var redirect         = $('input[name="redirect"]').val();
        var guid             = $('input[name="guid"]').val();
        var action           = "editProfile";
        
        var year             = $('input[name="year_of_birth"]').val();
        var lang             = $("#lan-itf-spoken").val();
        var country          = $("#itf-nationality").val();
        var code             = $('#itf-nationality option:selected').attr('country_code');
        var region           = $('#itf-nationality option:selected').attr('region');
        var gender           = $("#itfgender").val();
        var mobileNumber     = $("#mobile_number").val();

        var interestId       = $('#itfInterest').val();
        var audienceType     = $('#itfRole').val();

        var profilePic        = document.querySelector('input[name="secondary_logo"]').files[0];
        var profilePicCurrent = $("input[name=secondary_logo_current]").val();
        var nationality       = $('#nationality option:selected').attr('country_code');
        
        var validate = validate_user_form_inputs(title, firstname, lastname, country, year, gender, lang, mobileNumber);

        if (validate)
        {
            var formData = new FormData();
            formData.append('action', action);
            formData.append('token', token);
            formData.append('title', title);
            formData.append('firstname', firstname); 
            formData.append('lastname', lastname); 
            formData.append('guid', guid); 
            formData.append('year', year); 
            formData.append('lan', lang); 
            formData.append('country', country); 
            formData.append('code', code);
            formData.append('region', region); 
            formData.append('gender', gender);
            formData.append('profile_pic', profilePic);
            formData.append('profile_pic_current', profilePicCurrent);
            formData.append('mobile_number', mobileNumber);
            formData.append('itfInterest', interestId);
            formData.append('itfRole', audienceType);
            formData.append('nationality', nationality);


            $.ajax({
                url: '../content/ajax_itf_common.php',
                type: "post",
                dataType: "json",
                data: formData,
                contentType: false,
                cache: false,
                processData:false,
                success: function(data)
                {
                    try
                    {
                        cmsLoadingStatus = false;
                        if (data.output == "Success")
                        {   
                            alert('Profile Updated Successfully');
                            location.href = redirect;
                        }
                        else
                        {
                            if (data.output == "Access denied")
                            {
                                alert("Access denied");
                            }
                            else if (data.output == "incorrect_mobile_number_format")
                            {
                                alert("Incorrect mobile number format");
                            }
                            else
                            {
                                alert("Profile could not be updated. Please contact your administrator. Error: " + data.error.errors);
                            }
                        }
                    }
                    catch (e)
                    {
                        cmsLoadingStatus = false;
                        alert("Something went wrong.");
                    }
                }
            });
        }
        else
        {
            cmsLoadingStatus = false;
            alert("Fill in all the mandatory fields.");
        }
    });

    $('#tick-subscription').on('click', function() {

        var toggle = $("#subscription-div").attr("toggle");

        if (toggle == "hide")
        {
            $("#subscription-div").attr("toggle","show");
            $("#subscription-div").show();
        }
        else
        {
            $("#subscription-div").attr("toggle","hide");
            $("#subscription-div").hide();
        }

    });

    $("#itf-request-data").on('click', function() {
        var action = "requestdata";
        var token = $('input[name="token"]').val();
        $.ajax({
            url: '../content/ajax_itf_common.php',
            dataType: "json",
            data: {
                action          : action,
                token           : token,
            },
            success: function(data)
            {
                try
                {

                    if (data.output === "Success")
                    {
                       alert("Your request have been submitted. You will receive your information after 24 hours.");
                    }
                }
                catch (e)
                {
                    alert("Something went wrong.");
                }
            }
        });
    });


var enrolView = $.urlParam('enrol');

if ((enrolView != 1) && ($("#registrationForm").length) && ($.urlParam('academy') == 103))
{    
    $('#records-mismatch-err').hide();
    var entry = readEntry();
    var form  = $("#registrationForm");
    form.validate({
        errorPlacement: function errorPlacement(error, element) { element.before(error); },
        rules: {
            confirm: {
                equalTo: "#password"
            }
        }
    });

    var isMaintenance = false;
    if (isMaintenance)
    {
        $("#reg-mnt").show();
        return;
    }

    form.children("div").steps({
        headerTag: "h3",
        bodyTag: "section",
        transitionEffect: "slideLeft",
        labels: {
            next: entry[3198],
            previous: entry[3199],
            finish : entry[3200]
        },
        onStepChanging: function (event, currentIndex, newIndex)
        {
            form.validate().settings.ignore = ":disabled,:hidden";
            return form.valid();
        },
        onFinishing: function (event, currentIndex)
        {
            form.validate().settings.ignore = ":disabled";
            return form.valid();
        },
        onFinished: function (event, currentIndex)
        {
                var title                   = $('#salutation').val();
                var first_name              = $('#given-name').val();
                var last_name               = $('#family-name').val();
                var country                 = $('#itf-nationality').val();
                var cn_code                 = $('#itf-nationality option:selected').attr('country_code');
                var region                  = $('#itf-nationality option:selected').attr('region');
                var dob                     = $('#itfbirthdate').val();
                var gender                  = $('#itfgender').val();
                var lang                    = $('#itflang').val();
                var service                 = "7CA527AA-7F9C-4495-A628-105529CC05AB";//This is the service guiid for WAOS Portal in ITF Environment
                var token                   = $('input[name="token"]').val();
                var action                  = "itfwaosregister";
                var email                   = $("#userName").val();
                var pwd                     = $("#confirm").val();
                var salute                  = $("#salutation-inform").val();            
                var agreed_terms            = $('#agreed_to_terms').is(":checked");
                var salute                  = $("#salutation-inform").val();
                var is_na_survey_user       = $('input[name="itf_na_survey"]').val();   
                var parentFirstName         = $('#pFname').val();
                var parentLastName          = $('#pLname').val();
                var parentEmail             = $('#pEmail').val();
                var nationality             = $('#nationality option:selected').attr('country_code');
                
                if (!agreed_terms)
                {
                    alert("Please Accept our Terms and Conditions to proceed.");
                    event.preventDefault();
                    return;
                }
                
                validate = validate_user_form_inputs(title, first_name, last_name, country, dob, gender, lang);

                validemail = true;
                if (validate && validemail)
                {
                    $.ajax({
                        url: '../content/ajax_itf_common.php',
                        dataType: "json",
                        data: {
                            title            : title,
                            firstname        : first_name,
                            lastname         : last_name,
                            country          : country,
                            code             : cn_code,
                            region           : region,
                            dob              : dob,
                            gender           : gender,
                            email            : email,
                            password         : pwd,
                            lang             : lang,
                            service          : service,
                            token            : token,
                            action           : action,
                            salute           : salute,
                            na_survey_user   : is_na_survey_user,
                            parent_first_name: parentFirstName,
                            parent_last_name : parentLastName,
                            parent_email     : parentEmail,
                            nationality      : nationality
                        },
                        beforeSend: function(){
                            $('#loading-dialog').removeClass('fade');
                            $('#loading-dialog').css('background', '#ffffff');
                            $('#loading-dialog').css('opacity', '0.8');
                            $('#loading-dialog').fadeIn();
                            $('#records-mismatch-err').hide();
                        },
                        success: function(data)
                        {
                            try
                            {
                                 if (data.output)
                                 {
                                    $('#loading-dialog').addClass('fade');
                                    $('#loading-dialog').hide();
                                    location.href = data.output;
                                 }

                                 if(data.error)
                                 {
                                    alert(data.error);
                                    $('#loading-dialog').addClass('fade');
                                    $('#loading-dialog').hide();
                                    $('a[href="#previous"]').click();
                                 }

                                 if(data.warning)
                                 {                                    
                                    $('#loading-dialog').addClass('fade');
                                    $('#loading-dialog').fadeOut();                                    
                                    $('#records-mismatch-err').slideDown();
                                 }

                            }
                            catch (e)
                            {
                                alert("Something went wrong.");
                                $('#loading-dialog').addClass('fade');
                                $('#loading-dialog').hide();
                            }
                        }
                    });

                }
                else if (!validate)
                {
                    alert("Fill in all the mandatory fields.");
                }
                /*else if (!validemail)
                {
                    alert("Input a valid email address!");
                    $('a[href="#previous"]').click();
                }*/
        }
    });
}

    //Create a span for password validation error messages.
    /*if (($("#registrationForm").length) || ($("#newpwform").length))
    {
        var pw_validation_span = '<span class="pw-validate-error-area">'
                                + '<ul>'
                                + '<li class="pw-err-msg" id="six_char_limit">'+sixCharLimit+'</li>'
                                + '<li class="pw-err-msg" id="one_special_char_limit">'+oneSpecialCharLimit+'</li>'
                                + '<li class="pw-err-msg" id="one_num_limit">'+oneNumLimit+'</li>'
                                + '</ul>'
                                + '</span>';

        pwdError = "Please enter a valid email address!";

        var email_password_span =  '<span class="email_error">'
                                + '<ul>'
                                + '<li class="pw-err-msg" id="six_char_limit">'+pwdError+'</li>'                               
                                + '</ul>'
                                + '</span>';
    }

    //Registration Form & Password Reset Form
    if ($("#registrationForm").length)
    {
        $("#registrationForm input[type=password]").after(pw_validation_span);
        $("#registrationForm input[name='userName']").after(email_password_span);
    }
    else if ($("#confirm").length)
    {
        $("#confirm input[type=password]").after(pw_validation_span);
    }

    $("#registrationForm input[type=password], #confirm input[type=password]").on('keyup', function()
    {
        validatePassword($(this));
    });

    $("#registrationForm input[name='userName']").on('change', function()
    {
        val = $(this).val();
        var isvalid = validate_itf_email(val);      

        if (!isvalid)
        {
            $('.email_error').show();
            $('.pw-err-msg').show();
        }
        else
        {
            $('.email_error').hide();
            $('.pw-err-msg').hide();
        }
    });*/

    //Validate registration form & password reset form
    function validatePassword(thisObj)
    {
        var errorSpan = thisObj.next();
        var formatSpecialChar = /[!@$%^&*()_{}]+/;
        var formatNum = /[0-9]+/;
        var errCount = 0;

        if (thisObj.val().length >= 6)
        {
            errorSpan.find('#six_char_limit').hide();
        }
        else
        {
            errorSpan.find('#six_char_limit').show();
            errCount++;
        }

        if (formatSpecialChar.test(thisObj.val()))
        {
            errorSpan.find('#one_special_char_limit').hide();
        }
        else
        {
            errorSpan.find('#one_special_char_limit').show();
            errCount++;
        }

        if (formatNum.test(thisObj.val()))
        {
            errorSpan.find('#one_num_limit').hide();
        }
        else
        {
            errorSpan.find('#one_num_limit').show();
            errCount++;
        }

        if (errCount > 0)
        {

            $("a[href='#next']").parent().addClass("disabled");
            $("a[href='#next']").attr("disabled", true);
            $("a[href='#next']").attr("id", "next-btn");
            $("a[href='#next']").attr("href", "");
        }
        else
        {
            
            $("a[href='#next']").parent().removeClass("disabled");
            $("a[href='#next']").attr("disabled", false);
            $("#next-btn").attr("href", "#next");
        }
    }

    $('#new-email-itf').on('keyup', function() {
        var newmail = $(this).val();
        var validmail = validate_itf_email(newmail);
        if (!validmail)
        {
            $('#change-email-dialog').hide();
        }
        else
        {
             $('#change-email-dialog').show();
        }

    });

    $('#update-email-itf-button').on('click', function() 
    {

        var password                = $("#password-itf-confirm").val();
        var newmemail               = $('#new-email-itf').val();
        var token                   = $('input[name="token"]').val();
        var action                  = "upateitfemail";
        var userid                  = $('input[name="userid"]').val();
        var redirect                = $('input[name="redirect"]').val();


       $.ajax({
                url: '../content/ajax_itf_common.php',
                dataType: "json",
                data: {
                   password : password,
                   email    : newmemail,
                   token    : token,
                   action   : action,
                   userid   : userid
                },               
                success: function(data)
                {
                    try
                    {
                         if (data.output)
                         {                            
                            alert('Email updated successfully');
                            location.href = redirect;         
                         }
                         else if (data.error)
                         {
                            alert('There was an error encountered in updating your email');
                         }
                         else if (data.pwd)
                        {
                            alert('Password given does not match. Please try again.');
                        }

                    }
                    catch (e)
                    {
                        alert("Something went wrong.");
                        
                    }
                }
            });

    })

    if ($(".prereq_courses").length) {
        $(".prereq_courses").select2({
            placeholder: 'Please select...',
            theme: 'bootstrap-5'
        });
    }    

    $("#itfbirthdate").on("change", function(){
        var dob             = $("#itfbirthdate").val();
        var today           = new Date();
        var birthDate       = new Date(dob);
        var age             = today.getFullYear() - birthDate.getFullYear();
        var m               = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        if (age < 18) {
            $("#parentsDetails").show();
        } else {
            $("#pFname").val("");
            $("#pLname").val("");
            $("#pEmail").val("");
            $("#parentsDetails").hide();

        }
    });

    var summerNoteOptions = {
            minHeight: 300,
            dialogsInBody: true,
            //,airMode: true
            codemirror: { // codemirror options
                theme: 'monokai'
            },
            toolbar: [
                // [groupName, [list of button]]
                ['style', ['bold', 'italic', 'underline', 'strikethrough', 'clear']],
                ['insert', ['link', 'unlink']],
                ['color', ['color']],
                ['para', ['ul', 'ol', 'paragraph']],
                
            ]
    };

    if ($('#summary').length)
    {        
        $("textarea").each(function(){
            var id = $(this).attr('id');
            if (id && id.indexOf('summary') >= 0)
            {
                $(this).summernote(summerNoteOptions);
            }
        });
    }

    $('textarea[name="content_video_embed_codes"]').on('change', function(){
        var contentType = $('#itf_type').val();

        if (contentType == "youtube_video")
        {            
            var token       = $('input[name="token"]').val();
            var youtubeUrl  = $(this).val();

            $.ajax({
                url: '../content/ajax_itf_common.php',
                type: "post",
                dataType: "json",
                async: false,
                data: {
                    token       : $('input[name="token"]').val(),
                    action      : 'read_youtube_thumbnails',                    
                    content_video_embed_codes  : youtubeUrl
                },
                success: function(data)
                {                
                    if (data.output)
                    {
                        $('#media_content_url').replaceWith(data.output);
                    }
                    else
                    {
                        alert(data.error);
                    }
                }
            });
        }        

    })

    checkEbookMessage();

    /*if ($("#itfbirthdate").length)
    {        
        $("#itfbirthdate").datepicker({
              dateFormat: 'yy-mm-dd',
              changeMonth: true,
              changeYear: true,
              yearRange: '1900:+0', // Adjust the range to suit your application
              maxDate: '0' // Maximum date selectable is today's date
        });
    }*/


});

//===== END DOCUMENT READY FUNCTIONS =====

function get_select_multiple_data(id)
{
    var arrSelected = [];
    $('#'+id).each(function(){
        var selected = $(this).find("option:selected");
        selected.each(function(idx, val) {
            arrSelected.push(val.value);

         });
    });
    return arrSelected;
}


function check_current_itf_cat()
{
    var category_id = $("#category_level_1_id").val();
    var category_check = $("#category-check").val();
    var content_item_id = $("input[name=id]").val();
    var token = $("input[name=token]").val();

    if (category_check !== "edit-category")
    {
        jQuery.ajax({
            type: "POST",
            url: "../content/ajax_itf_category.php",
            data: {
                id          : category_id,
                content_id  : content_item_id,
                ptToken     : token
            },
            success: function (data) {
                $("#subcategory_level_1_id option").remove();
                $("#subcategory_level_1_id").append(data);
            }
        });
    }
}

function get_generic_text_select2()
{
    var return_arr = [];    

    $.ajax({
        type: "POST",        
         dataType: "json",
        url:  '../content/ajax_itf_get_select2.php',         
        success: function (data) 
        {          
            $("#player-level-filter").select2({
                placeholder: data.results.one, 
                width: '100%',
                theme: 'bootstrap-5'
            });
            $("#media-type-filter").select2({
                placeholder: data.results.two, 
                width: '100%',
                theme: 'bootstrap-5'
            });
            $("#topic-filter").select2({
                placeholder: data.results.three, 
                width: '100%',
                theme: 'bootstrap-5'
            });
            $("#expert-filter").select2({
                placeholder: data.results.four,
                width: '100%',
                theme: 'bootstrap-5'
            });
        }
    });

  
}

function check_if_option_exist(select_box_name, option_check_val)
{
    var exists = false;
    $('.' +select_box_name+' option').each(function()
    {
        if (this.value == option_check_val)
        {
           exists = true;

        }
    });
    return exists;
}

function addItemsManuallySelect2(div_name, values)
{    
    var data =
    {
        id: values,
        text: values
    };

    var newOption = new Option(data.text, data.id, true, true);
    $('.'+div_name).append(newOption).trigger('change');
}

function validate_user_form_inputs(title, first_name, last_name, country, dob, gender, lang, mobileNumber)
{
    var allvalid     = true;
    var nationality  = $('#nationality').val();
    if (title.trim() === null) 
    {
        allvalid = false;
        return allvalid;
    }
    else if (first_name === "")
    {
        allvalid = false;
        return allvalid;
    }
    else if (last_name === "")
    {
        allvalid = false;
        return allvalid;
    }
    else if (country === null )
    {
        allvalid = false;
        return allvalid;
    }
    else if (gender === null)
    {
        allvalid = false;
        return allvalid;
    }
    else if (lang === null)
    {
        allvalid = false;
        return allvalid;
    }
    else if (dob === "")
    {
        allvalid = false;
        return allvalid;
    }
    else if (mobileNumber === "")
    {
        allvalid = false;
        return allvalid;
    }
    else if (dob !== "")
    {
        var dob             = $("#itfbirthdate").val();
        var today           = new Date();
        var birthDate       = new Date(dob);
        var age             = today.getFullYear() - birthDate.getFullYear();
        var m               = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        const selectedDate = new Date(dob);
        const minDate = new Date('1901-01-01');

        if (selectedDate < minDate) {
            alert('Please enter a birth date after January 1, 1901');
            allvalid = false;
            return allvalid;
        }


        if (age < 18) {
            var pFname  = $("#pFname").val();
            var pLname  = $("#pLname").val();
            var pEmail  = $("#pEmail").val();

            if (pFname === "" || pLname === "" || pEmail === "")
            {
                allvalid = false;
                return allvalid;
            }
        } 
    }
    if (nationality === null || nationality === "null") {
        allvalid = false;
        return allvalid;
    }
   
    return allvalid;
}

function increase_height_itf_video()
{
    if($('.kWidgetIframeContainer').length)
    {
        $('.kWidgetIframeContainer').css("width","100%");
    }
    if($('.vcamso_presentation_player').length)
    {
        $('.vcamso_presentation_player').css("width","100%");
    }
}

function validate_itf_email(email) {
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

function pleasewait() {
    $('#loading-dialog').show();
}

function reload_banners_table() {
    var token = $("input[name=token]").val();
    var data_url = "../content/ajax_itf_common.php?action=read_banners_table&token="+token+"&academy="+$("input[name=academy]").val();
    $('#itf-banners-tbl').DataTable().ajax.url(data_url).load();   
}

function reload_content_items_table() {
    var token = $("input[name=token]").val();
    var data_url = "../content/ajax_read_itf_content_items.php?token="+token+"&academy="+$("input[name=academy]").val();
    $('#content-items-tbl').DataTable().ajax.url(data_url).load();   
} 

function checkEbookMessage() {
    if ($('#uniqueIdForEbook').length) {
        var token = $("input[name=token]").val();
        var message = "";
        $.ajax({
                url: '../content/ajax_itf_common.php',
                dataType: "json",
                data: {                  
                   token    : token,
                   action   : "read_ebook_message_notification"
                },               
                success: function(data) {
                    if (data.output.length >= 1) {
                        message = data.output;                        
                        renderEbookMessage(message);
                    }
                }
        });
    }
}  

var renderModalPrototypeItf = {
      type: '',
      size: '',
      title: 'Modal Title',
      body: 'Example Body text',
      footer: '',
      mount: 'body',
      dismiss: true,
      renderModal: function() {
        var closeBtn = (this.dismiss) ? '<button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>' : '';
        var footerHTML = ((this.footer == '') || (this.footer == undefined))  ? '' : '<div class="modal-footer">' + this.footer + '</div>';
        var html = '<div class="modal fade ' + this.type + '" id="global-modal">'+
          '<div class="modal-dialog ' + this.size + '" role="document">'+
            '<div class="modal-content">'+
                '<div class="modal-header">' +
                '<h4 class="modal-title">' + this.title + '</h4>'+
                closeBtn + 
              '</div>'+
              '<div class="modal-body">' + this.body + '</div>' + footerHTML + '</div>'+
          '</div>'+
        '</div>';        
        $(this.mount).append(html);

        var modal = $('#global-modal');
        modal.modal({
            'show': true,
            'backdrop': this.dismiss
        });
    }
};


function renderEbookMessage(message) {       
    var forms = '<div class="form-group"><p>'+message+'</p></div> <div class="text-right"> <button type="button" class="btn btn-secondary" id="btn--remove-notes-dialog" data-dismiss="modal" style="margin-right:5px">Close</button><button type="button" class="btn btn-success" id="ebookAcceptButton">OK</button></div>';
    var type = '',
    title = "",
    body = forms,
    footer = '';
    var modal = Object.create(renderModalPrototypeItf);
    modal.title = title;
    modal.body = forms;
    modal.dismiss = true;
    modal.footer = '';
    modal.renderModal();
}


(function() {

  // store the slider in a local variable
  var $window = $(window),
      flexslider = [];

  // tiny helper function to add breakpoints
  function getGridSize(coEf) {
    var contentWrapperWidth = $('.content-wrapper').outerWidth();
    if(coEf == 2) return 1;
    return  (contentWrapperWidth < 540 * coEf) ? 1 :
            (contentWrapperWidth < 840 * coEf) ? 2 :
            (contentWrapperWidth < 1160 * coEf) ? 3 : 4;
  }

  $(window).on('load', function() {
    $('.icoach-cms-wrapper .flexslider').each(function() {
      var $this = $(this);
      var thisId = $this.attr('id');
      $this.flexslider({
        animation: "slide",
        animationLoop: false,
        itemWidth: 210,
        itemMargin: 24,
        controlNav: false,
        prevText: '',
        nextText: '',
        minItems: getGridSize(thisId == 'recommend-itf-slide' || thisId == 'latest-items-slide' ? 2 : 1), // use function to pull in initial value
        maxItems: getGridSize(thisId == 'recommend-itf-slide' || thisId == 'latest-items-slide' ? 2 : 1), // use function to pull in initial value
        start: function(slider){
          var thisId = slider.attr('id');
            //set slider instance to flexslider variable
            flexslider[thisId] = { vars: {} };
        			flexslider[thisId] = slider;
      	}
      });
    });
    $('.flexslider.full-width-hero').each(function() {
      var $this = $(this);
      var thisId = $this.attr('id');
      $this.flexslider({
        animation: "slide",
        animationLoop: true,
        controlNav: true,
        prevText: '',
        nextText: '',
      });
    });
     $('.flexslider.programme-banner').each(function() {
      var $this = $(this);
      var thisId = $this.attr('id');
      $this.flexslider({
        animation: "slide",
        animationLoop: true,
        controlNav: false,
        itemWidth: 490,
        itemMargin: 10,
        prevText: '',
        nextText: '',
      });
    });
  });

  
  var flexsliderResizeTimeout;
  // check grid size on resize event
  $window.on('resize', (function() {

    $('.icoach-cms-wrapper .flexslider').each(function() {
      var $this = $(this);
      var gridSize = 0;
      var thisId = $this.attr('id');
      if(thisId == 'recommend-itf-slide' || thisId == 'latest-items-slide') {
        gridSize = getGridSize(2);
      } else {
        gridSize = getGridSize(1);
      }

      flexslider[thisId].vars.minItems = gridSize;
      flexslider[thisId].vars.maxItems = gridSize;

      if(gridSize < 3) {
        flexslider[thisId].vars.animationLoop = true;
      } else {
        flexslider[thisId].vars.animationLoop = false;
      }

    });

  }));


}());


$(document).ready(function() {
  var curSetVal = localStorage.getItem("sidebarCollapse");
  if(curSetVal == 'true') {
        $('body').addClass('sidebar-collapse'); 
   }    

    if ($("#interest-form").length) {  

        var form          = document.getElementById("interest-form");
        var steps         = form.querySelectorAll(".form-step");
        var nextButtons   = form.querySelectorAll(".next-button");
        var prevButtons   = form.querySelectorAll(".previous-button");
        var currentStep   = 0;

        function showStep(stepIndex) {
          steps.forEach((step, index) => {
            if (index === stepIndex) {
              step.style.display = "block";
            } else {
              step.style.display = "none";
            }
          });
        }

        function nextStep() {
          if (currentStep < steps.length - 1) {
            currentStep++;
            showStep(currentStep);
          }
        }

        function prevStep() {
          if (currentStep > 0) {
            currentStep--;
            showStep(currentStep);
          }
        }

        nextButtons.forEach((button) => {
          button.addEventListener("click", (e) => {
            e.preventDefault();
            nextStep();
          });
        });

        prevButtons.forEach((button) => {
          button.addEventListener("click", (e) => {
            e.preventDefault();
            prevStep();
          });
        });      

        showStep(currentStep);
    }

    throttleItfScroll();
  
    $(window).scroll(function () {
       throttleItfScroll();
    });

    $('.toggle-menu').click(function() {
        $('#navbar-collapse').toggleClass('show-navbar-collapse');
    });
      
    
});
$(window).on('load', function() {
  $('.sidebar-toggle').on('click', function() {
    var curSetVal = localStorage.getItem("sidebarCollapse");
    if(curSetVal == 'true') {
      localStorage.setItem("sidebarCollapse", "false");
    } else {
      localStorage.setItem("sidebarCollapse", "true");
    }
    setTimeout(function() {
      if (typeof(Event) === 'function') {
        // modern browsers
        window.dispatchEvent(new Event('resize'));
      } else {
        // for IE and other old browsers
        // causes deprecation warning on modern browsers
        var evt = window.document.createEvent('UIEvents');
        evt.initUIEvent('resize', true, false, window, 0);
        window.dispatchEvent(evt);
      }
    }, 400);
    setTimeout(function() {
      if (typeof(Event) === 'function') {
        // modern browsers
        window.dispatchEvent(new Event('resize'));
      } else {
        // for IE and other old browsers
        // causes deprecation warning on modern browsers
        var evt = window.document.createEvent('UIEvents');
        evt.initUIEvent('resize', true, false, window, 0);
        window.dispatchEvent(evt);
      }
    }, 550);
  });
  // $('body').on('click', '.itf-share-launch', function() {
  //   $(this).siblings('.pw-widget').find('.pw-button-more')[0].click();
  // });
  //window.postPlatform.createWidget('.fa-share', { hover: true, buttons: ['facebook', { id: 'twitter', size: '20'}] });

});

$(document).on('click', "#ebookAcceptButton", function() {
    var policyType = 4;
    $.ajax({
        url:'../admin/ajax_accept_cookie_policy.php',
        dataType:'json',
        type:'post',
        data: {action: "accept", policyType: policyType, hasAcceptedCookies: 1},
        success: function(data) {
            if (data.success != null)
            {
                $('#btn--remove-notes-dialog').trigger('click');
            }
            else if (data.error != null)
            {
                $('#btn--remove-notes-dialog').trigger('click');
            }
        },
        error: function(data) {
            alert('Something went wrong');
        }
    });
        
});

$(document).on('click', ".skip-game-type-btn", function() {
    var action  = "save_skip_game_types";
    var token   = $("input[name=pTtoken]").val();

    $.ajax({
            url: '../content/ajax_itf_common.php',
            dataType: "json",
            data: {             
                token           : token,
                action          : action
            },          
            success: function(data)
            {               
                location.href = "/?academy=103&area=online";                
            }

        });
        
});

$(document).on('click', ".game-types-parent-wrapper", function(e) {
    var value = $(this).find(".cta-game-type-btn.save-user-role-type").attr("data-id");
    var type = $(this).find(".cta-game-type-btn.save-user-role-type").attr("type");
    var name = $(this).find(".cta-game-type-btn.save-user-role-type").attr("wrap");

    if (value == undefined)
    {
        var value = $(this).find(".cta-game-type-btn.user-not-logged-in").attr("data-id");
        var type = $(this).find(".cta-game-type-btn.user-not-logged-in").attr("type");
        var name = $(this).find(".cta-game-type-btn.user-not-logged-in").attr("wrap");
    }
    var action  = "save_user_role_types";
    var token   = $("input[name=pTtoken]").val();
    //console.log(name);
    e.preventDefault();
    
    $.ajax({
            url: '../content/ajax_itf_common.php',
            dataType: "json",
            data: {             
                token           : token,
                action          : action,
                type            : type,
                role_id         : value
            },          
            success: function(data)
            {               
                console.log(data.output);
                if (data.output == "not_logged_in") {
                    location.href = "/?academy=103&view=login&interest="+name; 
                } else {
                    location.href = "/?academy=103&area=online"; 
                }
                
            }
    });
});

$(document).on('click', ".save-user-role-type", function(e) {      
    var action  = "save_user_role_types";
    var token   = $("input[name=waToken]").val();
    var type    = $(this).attr("type");
    var value   = $(this).attr("data-id");
    var name    = $(this).attr("wrap");
    e.preventDefault();
    $.ajax({
            url: '../content/ajax_itf_common.php',
            dataType: "json",
            data: {             
                token           : token,
                action          : action,
                type            : type,
                role_id         : value
            },          
            success: function(data)
            { 
                if (type == "role") {
                    if (data.output == "itf_user_interest_id") {
                        location.href = "/?academy=103&area=online";  
                    } else {
                        $('#step-1').css("display", "none");
                        $('#step-2').css("display", "block");                   
                        $('.dropdown-toggle').html(name);
                    }                   

                } else {
                    location.href = "/?academy=103&area=online";        
                }
            }
    });        
});


$(document).on('click', ".user-not-logged-in", function(e) { 
    var token   = $("input[name=pTtoken]").val();
    var type    = $(this).attr("type");
    var value   = $(this).attr("data-id");              
    var action  = "save_role_not_logged_in";
    var name    = $(this).attr("name");                           
                       
    e.preventDefault();   
    $.ajax({
            url: '../content/ajax_itf_common.php',
            dataType: "json",
            data: {             
                token           : token,
                action          : action,
                type            : type,
                role_id         : value
            },          
            success: function(data)
            { 
                location.href = "/?academy=103&view=login&role="+name; 
            }
    });             
   
});

function throttleItfScroll() {
  var scroll = $(window).scrollTop();
  var width = $(window).width();

  if (scroll >= 5 || width < 480) {
    $('.cu-header').addClass('active');
  } else {
    $('.cu-header').removeClass('active');
  }
}

function toggleItfNav() {
  var button = $('.nav-container');

  if (button.hasClass('active')) {
    button.removeClass('active');
  } else {
    button.addClass('active');
  }
}

     
